import { usePageTitle } from '@capturi/react-utils'
import request from '@capturi/request'
import { useOrganization } from '@capturi/stores'
import { Spinner } from '@capturi/ui-components'
import {
  Box,
  Button,
  Circle,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import IframeResizer from 'iframe-resizer-react'
import React, { useState } from 'react'
import { MdTimelapse } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { useAllMetabaseReports } from './useMetabaseReports'

const MetabaseReportIframe: React.FC = () => {
  usePageTitle('Report')
  const { reportUid } = useParams()
  const [refreshInterval, setRefreshInterval] = useState('')
  const { data, isLoading, error } = useQuery({
    staleTime: Number.POSITIVE_INFINITY,
    queryFn: async () =>
      request.get<{ url: string }>(
        `metabase/reports/${reportUid}?api-version=3.3`,
      ),
    queryKey: ['analytics/dashboard', reportUid],
  })

  const { uid: organizationUid } = useOrganization()

  const { data: metabaseReports } = useAllMetabaseReports(organizationUid)

  const host = metabaseReports?.reports.find(
    (report) => report.uid === reportUid,
  )?.host

  if (error)
    return (
      <Box>
        <Trans>
          An error occurred while loading dashboard. Please try refreshing the
          page
        </Trans>
      </Box>
    )
  if (isLoading)
    return (
      <Flex justifyContent="center" mt="40%">
        <Spinner size="xl" />
      </Flex>
    )
  return (
    <>
      <Flex
        bg="radial-gradient(90.94% 95.55% at 79.16% 25.21%, #FFF 47.45%, rgba(255, 255, 255, 0.00) 100%)"
        alignItems="end"
        flexDir="column"
        pos="absolute"
        right="12"
        top="0"
        h="250px"
        w="300px"
      >
        <Flex pos="relative" right="4" top="128px">
          {host === 'Sync' && (
            <Menu placement="bottom-end">
              <Tooltip
                hasArrow
                label="Auto refresh timer"
                aria-label="auto refresh timer"
              >
                <MenuButton
                  color="gray.800"
                  disabled={true}
                  leftIcon={<MdTimelapse />}
                  as={Button}
                >
                  <Trans>Auto refresh interval</Trans>
                </MenuButton>
              </Tooltip>
              {refreshInterval && (
                <Circle
                  border="1px solid white"
                  background="success"
                  position="absolute"
                  size="7px"
                  left="24px"
                  top="7px"
                />
              )}
              <MenuList>
                <MenuItem onClick={() => setRefreshInterval('')}>
                  <Trans>None</Trans>
                </MenuItem>
                <MenuItem onClick={() => setRefreshInterval('&refresh=60')}>
                  <Trans>1 minute</Trans>
                </MenuItem>
                <MenuItem onClick={() => setRefreshInterval('&refresh=300')}>
                  <Trans>5 minutes</Trans>
                </MenuItem>
                <MenuItem onClick={() => setRefreshInterval('&refresh=600')}>
                  <Trans>10 minutes</Trans>
                </MenuItem>
                <MenuItem onClick={() => setRefreshInterval('&refresh=900')}>
                  <Trans>15 minutes</Trans>
                </MenuItem>
                <MenuItem onClick={() => setRefreshInterval('&refresh=1800')}>
                  <Trans>30 minutes</Trans>
                </MenuItem>
                <MenuItem onClick={() => setRefreshInterval('&refresh=3600')}>
                  <Trans>60 minutes</Trans>
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
      <Box>
        <IframeResizer
          title="report"
          src={`${data?.url}${refreshInterval}`}
          style={{ width: '100%' }}
        />
      </Box>
    </>
  )
}

export default MetabaseReportIframe
